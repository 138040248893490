export default class GlobalValues {
    countries = [
        { name: 'Bangladesh', value: 'Bangladesh' }
    ];
    divisions = [
        { country: 'Bangladesh', value: 'Barisal' },
        { country: 'Bangladesh', value: 'Chittagong' },
        { country: 'Bangladesh', value: 'Dhaka' },
        { country: 'Bangladesh', value: 'Mymensingh' },
        { country: 'Bangladesh', value: 'Khulna' },
        { country: 'Bangladesh', value: 'Rajshahi' },
        { country: 'Bangladesh', value: 'Rangpur' },
        { country: 'Bangladesh', value: 'Sylhet' }
    ];
    districts = [
        { division: 'Barisal', value: 'Barguna' },
        { division: 'Barisal', value: 'Barisal' },
        { division: 'Barisal', value: 'Bhola' },
        { division: 'Barisal', value: 'Jhalokati' },
        { division: 'Barisal', value: 'Patuakhali' },
        { division: 'Barisal', value: 'Pirojpur' },
        { division: 'Chittagong', value: 'Bandarban' },
        { division: 'Chittagong', value: 'Brahmanbaria' },
        { division: 'Chittagong', value: 'Chandpur' },
        { division: 'Chittagong', value: 'Chittagong' },
        { division: 'Chittagong', value: 'Comilla' },
        { division: 'Chittagong', value: "Cox's Bazar" },
        { division: 'Chittagong', value: 'Feni' },
        { division: 'Chittagong', value: 'Khagrachhari' },
        { division: 'Chittagong', value: 'Lakshmipur' },
        { division: 'Chittagong', value: 'Noakhali' },
        { division: 'Chittagong', value: 'Rangamati' },
        { division: 'Dhaka', value: 'Dhaka' },
        { division: 'Dhaka', value: 'Faridpur' },
        { division: 'Dhaka', value: 'Gazipur' },
        { division: 'Dhaka', value: 'Gopalganj' },
        { division: 'Dhaka', value: 'Kishoreganj' },
        { division: 'Dhaka', value: 'Madaripur' },
        { division: 'Dhaka', value: 'Manikganj' },
        { division: 'Dhaka', value: 'Munshiganj' },
        { division: 'Dhaka', value: 'Narayanganj' },
        { division: 'Dhaka', value: 'Narsingdi' },
        { division: 'Dhaka', value: 'Rajbari' },
        { division: 'Dhaka', value: 'Shariatpur' },
        { division: 'Dhaka', value: 'Tangail' },
        { division: 'Khulna', value: 'Bagerhat' },
        { division: 'Khulna', value: 'Chuadanga' },
        { division: 'Khulna', value: 'Jessore' },
        { division: 'Khulna', value: 'Jhenaidah' },
        { division: 'Khulna', value: 'Khulna' },
        { division: 'Khulna', value: 'Kushtia' },
        { division: 'Khulna', value: 'Magura' },
        { division: 'Khulna', value: 'Meherpur' },
        { division: 'Khulna', value: 'Narail' },
        { division: 'Khulna', value: 'Satkhira' },
        { division: 'Mymensingh', value: 'Jamalpur' },
        { division: 'Mymensingh', value: 'Mymensingh' },
        { division: 'Mymensingh', value: 'Netrokona' },
        { division: 'Mymensingh', value: 'Sherpur' },
        { division: 'Rajshahi', value: 'Bogura' },
        { division: 'Rajshahi', value: 'Joypurhat' },
        { division: 'Rajshahi', value: 'Naogaon' },
        { division: 'Rajshahi', value: 'Natore' },
        { division: 'Rajshahi', value: 'Nawabganj' },
        { division: 'Rajshahi', value: 'Pabna' },
        { division: 'Rajshahi', value: 'Rajshahi' },
        { division: 'Rajshahi', value: 'Sirajganj' },
        { division: 'Rangpur', value: 'Dinajpur' },
        { division: 'Rangpur', value: 'Gaibandha' },
        { division: 'Rangpur', value: 'Kurigram' },
        { division: 'Rangpur', value: 'Lalmonirhat' },
        { division: 'Rangpur', value: 'Nilphamari' },
        { division: 'Rangpur', value: 'Panchagarh' },
        { division: 'Rangpur', value: 'Rangpur' },
        { division: 'Rangpur', value: 'Thakurgaon' },
        { division: 'Sylhet', value: 'Habiganj' },
        { division: 'Sylhet', value: 'Moulvibazar' },
        { division: 'Sylhet', value: 'Sunamganj' },
        { division: 'Sylhet', value: 'Sylhet' }
    ];
    thanas = [
        { district: 'Shariatpur', value: 'Bhedarganj' },
        { district: 'Shariatpur', value: 'Damudya' },
        { district: 'Shariatpur', value: 'Gosairhat' },
        { district: 'Shariatpur', value: 'Naria' },
        { district: 'Shariatpur', value: 'Shariatpur Sadar' },
        { district: 'Shariatpur', value: 'Shakhipur' },
        { district: 'Shariatpur', value: 'Zanjira' },

        { district: 'Barguna', value: 'Amtali' },
        { district: 'Barguna', value: 'Barguna Sadar' },
        { district: 'Barguna', value: 'Betagi' },
        { district: 'Barguna', value: 'Patharghata' },
        { district: 'Barguna', value: 'Taltali' },
        { district: 'Barisal', value: 'Agailjhara' },
        { district: 'Barisal', value: 'Babuganj' },
        { district: 'Barisal', value: 'Bakerganj' },
        { district: 'Barisal', value: 'Banaripara' },
        { district: 'Barisal', value: 'Barisal Sadar' },
        { district: 'Barisal', value: 'Gournadi' },
        { district: 'Barisal', value: 'Hizla' },
        { district: 'Barisal', value: 'Mehendiganj' },
        { district: 'Barisal', value: 'Muladi' },
        { district: 'Barisal', value: 'Wazirpur' },

        { district: 'Bhola', value: 'Bhola Sadar' },
        { district: 'Bhola', value: 'Daulatkhan' },
        { district: 'Bhola', value: 'Burhanuddin' },
        { district: 'Bhola', value: 'Tazumuddin' },
        { district: 'Bhola', value: 'Lalmohan' },
        { district: 'Bhola', value: 'Char Fasson' },
        { district: 'Bhola', value: 'Manpura' },

        { district: 'Jhalokati', value: 'Jhalokati Sadar' },
        { district: 'Jhalokati', value: 'Kathalia' },
        { district: 'Jhalokati', value: 'Nalchity' },
        { district: 'Jhalokati', value: 'Rajapur' },

        { district: 'Patuakhali', value: 'Dumki' },
        { district: 'Patuakhali', value: 'Patuakhali Sadat' },
        { district: 'Patuakhali', value: 'Mirzaganj' },
        { district: 'Patuakhali', value: 'Bauphal' },
        { district: 'Patuakhali', value: 'Galachipa' },
        { district: 'Patuakhali', value: 'Dashmina' },
        { district: 'Patuakhali', value: 'Rangabali' },
        { district: 'Patuakhali', value: 'Kalapara' },

        { district: 'Pirojpur', value: 'Bhandaria' },
        { district: 'Pirojpur', value: 'Kawkhali' },
        { district: 'Pirojpur', value: 'Mathbaria' },
        { district: 'Pirojpur', value: 'Nazirpur' },
        { district: 'Pirojpur', value: 'Nesarabad' },
        { district: 'Pirojpur', value: 'Pirojpur Sadar' },
        { district: 'Pirojpur', value: 'Indurkani' },

        { district: 'Bandarban', value: 'Bandarban Sadar' },
        { district: 'Bandarban', value: 'Thanchi' },
        { district: 'Bandarban', value: 'Lama' },
        { district: 'Bandarban', value: 'Naikhongchhari' },
        { district: 'Bandarban', value: 'Ali kadam' },
        { district: 'Bandarban', value: 'Rowangchhari' },
        { district: 'Bandarban', value: 'Ruma' },

        { district: 'Brahmanbaria', value: 'Brahmanbaria Sadar' },
        { district: 'Brahmanbaria', value: 'Kasba' },
        { district: 'Brahmanbaria', value: 'Akhaura' },
        { district: 'Brahmanbaria', value: 'Ashuganj' },
        { district: 'Brahmanbaria', value: 'Bancharampur' },
        { district: 'Brahmanbaria', value: 'Bijoynagar' },
        { district: 'Brahmanbaria', value: 'Nasirnagar' },
        { district: 'Brahmanbaria', value: 'Nabinagar' },
        { district: 'Brahmanbaria', value: 'Sarail' },

        { district: 'Chandpur', value: 'Chandpur Sadar' },
        { district: 'Chandpur', value: 'Faridganj' },
        { district: 'Chandpur', value: 'Haimchar' },
        { district: 'Chandpur', value: 'Hajiganj' },
        { district: 'Chandpur', value: 'Kachua' },
        { district: 'Chandpur', value: 'Matlab Dakshin' },
        { district: 'Chandpur', value: 'Matlab Uttar' },
        { district: 'Chandpur', value: 'Shahrasti' },

        { district: 'Chittagong', value: 'Anwara' },
        { district: 'Chittagong', value: 'Banshkhali' },
        { district: 'Chittagong', value: 'Boalkhali' },
        { district: 'Chittagong', value: 'Chandanaish' },
        { district: 'Chittagong', value: 'Fatikchhari' },
        { district: 'Chittagong', value: 'Hathazari' },
        { district: 'Chittagong', value: 'Karnaphuli' },
        { district: 'Chittagong', value: 'Lohagara' },
        { district: 'Chittagong', value: 'Mirsharai' },
        { district: 'Chittagong', value: 'Patiya' },
        { district: 'Chittagong', value: 'Rangunia' },
        { district: 'Chittagong', value: 'Raozan' },
        { district: 'Chittagong', value: 'Sandwip' },
        { district: 'Chittagong', value: 'Satkania' },
        { district: 'Chittagong', value: 'Sitakunda' },

        { district: 'Comilla', value: 'Barura' },
        { district: 'Comilla', value: 'Brahmanpara' },
        { district: 'Comilla', value: 'Burichong' },
        { district: 'Comilla', value: 'Comilla Sadar' },
        { district: 'Comilla', value: 'Comilla Sadar Dakshin' },
        { district: 'Comilla', value: 'Chandina' },
        { district: 'Comilla', value: 'Chauddagram' },
        { district: 'Comilla', value: 'Daudkandi' },
        { district: 'Comilla', value: 'Debidwar' },
        { district: 'Comilla', value: 'Homna' },
        { district: 'Comilla', value: 'Laksam' },
        { district: 'Comilla', value: 'Lalmai' },
        { district: 'Comilla', value: 'Monohorgonj' },
        { district: 'Comilla', value: 'Meghna' },
        { district: 'Comilla', value: 'Muradnagar' },
        { district: 'Comilla', value: 'Nangalkot' },
        { district: 'Comilla', value: 'Titas' },

        { district: "Cox's Bazar", value: 'Chakaria' },
        { district: "Cox's Bazar", value: "Cox's Bazar Sadar" },
        { district: "Cox's Bazar", value: 'Kutubdia' },
        { district: "Cox's Bazar", value: 'Maheshkhali' },
        { district: "Cox's Bazar", value: 'Ramu' },
        { district: "Cox's Bazar", value: 'Teknaf' },
        { district: "Cox's Bazar", value: 'Ukhia' },
        { district: "Cox's Bazar", value: 'Pekua' },
        { district: "Cox's Bazar", value: 'Eidgaon' },

        { district: 'Feni', value: 'Feni Sadar' },
        { district: 'Feni', value: 'Daganbhuiyan' },
        { district: 'Feni', value: 'Chhagalnaiya' },
        { district: 'Feni', value: 'Sonagazi' },
        { district: 'Feni', value: 'Parshuram' },
        { district: 'Feni', value: 'Fulgazi' },

        { district: 'Khagrachhari', value: 'Dighinala' },
        { district: 'Khagrachhari', value: 'Khagrachhari Sadar' },
        { district: 'Khagrachhari', value: 'Lakshmichhari' },
        { district: 'Khagrachhari', value: 'Mahalchhari' },
        { district: 'Khagrachhari', value: 'Manikchhari' },
        { district: 'Khagrachhari', value: 'Matiranga' },
        { district: 'Khagrachhari', value: 'Panchhari' },
        { district: 'Khagrachhari', value: 'Ramgarh' },
        { district: 'Khagrachhari', value: 'Guimara' },

        { district: 'Lakshmipur', value: 'Lakshmipur Sadar' },
        { district: 'Lakshmipur', value: 'Ramganj' },
        { district: 'Lakshmipur', value: 'Raipur' },
        { district: 'Lakshmipur', value: 'Ramgati' },
        { district: 'Lakshmipur', value: 'Kamalnagar' },

        { district: 'Noakhali', value: 'Senbagh' },
        { district: 'Noakhali', value: 'Begumganj' },
        { district: 'Noakhali', value: 'Chatkhil' },
        { district: 'Noakhali', value: 'Companiganj' },
        { district: 'Noakhali', value: 'Noakhali Sadar' },
        { district: 'Noakhali', value: 'Hatiya' },
        { district: 'Noakhali', value: 'Kabirhat' },
        { district: 'Noakhali', value: 'Sonaimuri' },
        { district: 'Noakhali', value: 'Suborno Char' },
        { district: 'Noakhali', value: 'Bhashan Char' },

        { district: 'Rangamati', value: 'Rangamati Sadar' },
        { district: 'Rangamati', value: 'Belaichhari' },
        { district: 'Rangamati', value: 'Bagaichhari' },
        { district: 'Rangamati', value: 'Barkal' },
        { district: 'Rangamati', value: 'Juraichhari' },
        { district: 'Rangamati', value: 'Rajasthali' },
        { district: 'Rangamati', value: 'Kaptai' },
        { district: 'Rangamati', value: 'Langadu' },
        { district: 'Rangamati', value: 'Naniarchar' },
        { district: 'Rangamati', value: 'Kaukhali' },

        { district: 'Dhaka', value: 'Dhamrai' },
        { district: 'Dhaka', value: 'Dohar' },
        { district: 'Dhaka', value: 'Keraniganj' },
        { district: 'Dhaka', value: 'Nawabganj' },
        { district: 'Dhaka', value: 'Savar' },
        { district: 'Dhaka', value: 'Demra' },

        { district: 'Dhaka', value: 'Adabar' },
        { district: 'Dhaka', value: 'Badda' },
        { district: 'Dhaka', value: 'Bangsal' },
        { district: 'Dhaka', value: 'Bimanbandar' },
        { district: 'Dhaka', value: 'Cantonment' },
        { district: 'Dhaka', value: 'Chowkbazar' },
        { district: 'Dhaka', value: 'Darus Salam' },
        { district: 'Dhaka', value: 'Demra' },
        { district: 'Dhaka', value: 'Dhanmondi' },
        { district: 'Dhaka', value: 'Gendaria' },
        { district: 'Dhaka', value: 'Gulshan' },
        { district: 'Dhaka', value: 'Hazaribagh' },
        { district: 'Dhaka', value: 'Jatrabari' },
        { district: 'Dhaka', value: 'Kadamtali' },
        { district: 'Dhaka', value: 'Kafrul' },
        { district: 'Dhaka', value: 'Kalabagan' },
        { district: 'Dhaka', value: 'Kamrangirchar' },
        { district: 'Dhaka', value: 'Khilgaon' },
        { district: 'Dhaka', value: 'Khilkhet' },
        { district: 'Dhaka', value: 'Kotwali' },
        { district: 'Dhaka', value: 'Lalbagh' },
        { district: 'Dhaka', value: 'Mirpur Model' },
        { district: 'Dhaka', value: 'Mohammadpur' },
        { district: 'Dhaka', value: 'Motijheel' },
        { district: 'Dhaka', value: 'New Market' },
        { district: 'Dhaka', value: 'Pallabi' },
        { district: 'Dhaka', value: 'Paltan' },
        { district: 'Dhaka', value: 'Panthapath' },
        { district: 'Dhaka', value: 'Ramna' },
        { district: 'Dhaka', value: 'Rampura' },
        { district: 'Dhaka', value: 'Sabujbagh' },
        { district: 'Dhaka', value: 'Shah Ali' },
        { district: 'Dhaka', value: 'Shahbag' },
        { district: 'Dhaka', value: 'Sher-e-Bangla' },
        { district: 'Dhaka', value: 'Shyampur' },
        { district: 'Dhaka', value: 'Sutrapur' },
        { district: 'Dhaka', value: 'Tejgaon Industrial Area' },
        { district: 'Dhaka', value: 'Tejgaon' },
        { district: 'Dhaka', value: 'Turag' },
        { district: 'Dhaka', value: 'Uttar Khan' },
        { district: 'Dhaka', value: 'Uttara East' },
        { district: 'Dhaka', value: 'Uttara West' },
        { district: 'Dhaka', value: 'Vatara' },

        { district: 'Faridpur', value: 'Alfadanga' },
        { district: 'Faridpur', value: 'Bhanga' },
        { district: 'Faridpur', value: 'Boalmari' },
        { district: 'Faridpur', value: 'Charbhadrasan' },
        { district: 'Faridpur', value: 'Faridpur Sadar' },
        { district: 'Faridpur', value: 'Madhukhali' },
        { district: 'Faridpur', value: 'Nagarkanda' },
        { district: 'Faridpur', value: 'Sadarpur' },
        { district: 'Faridpur', value: 'Saltha' },

        { district: 'Gazipur', value: 'Gazipur Sadar' },
        { district: 'Gazipur', value: 'Kaliakair' },
        { district: 'Gazipur', value: 'Kapasia' },
        { district: 'Gazipur', value: 'Sreepur' },
        { district: 'Gazipur', value: 'Kaliganj' },

        { district: 'Gopalganj', value: 'Gopalganj Sadar' },
        { district: 'Gopalganj', value: 'Kashiani' },
        { district: 'Gopalganj', value: 'Kotalipara' },
        { district: 'Gopalganj', value: 'Muksudpur' },
        { district: 'Gopalganj', value: 'Tungipara' },

        { district: 'Kishoreganj', value: 'Kuliarchar' },
        { district: 'Kishoreganj', value: 'Hossainpur' },
        { district: 'Kishoreganj', value: 'Pakundia' },
        { district: 'Kishoreganj', value: 'Kishoreganj Sadar' },
        { district: 'Kishoreganj', value: 'Bajitpur' },
        { district: 'Kishoreganj', value: 'Austagram' },
        { district: 'Kishoreganj', value: 'Karimganj' },
        { district: 'Kishoreganj', value: 'Katiadi' },
        { district: 'Kishoreganj', value: 'Tarail' },
        { district: 'Kishoreganj', value: 'Itna' },
        { district: 'Kishoreganj', value: 'Nikli' },
        { district: 'Kishoreganj', value: 'Mithamain' },
        { district: 'Kishoreganj', value: 'Bhairab' },

        { district: 'Madaripur', value: 'Madaripur Sadar' },
        { district: 'Madaripur', value: 'Kalkini' },
        { district: 'Madaripur', value: 'Dasar' },
        { district: 'Madaripur', value: 'Rajoir' },
        { district: 'Madaripur', value: 'Shibchar' },

        { district: 'Manikganj', value: 'Manikganj Sadar' },
        { district: 'Manikganj', value: 'Singair' },
        { district: 'Manikganj', value: 'Shivalaya' },
        { district: 'Manikganj', value: 'Saturia' },
        { district: 'Manikganj', value: 'Harirampur' },
        { district: 'Manikganj', value: 'Ghior' },
        { district: 'Manikganj', value: 'Daulatpur' },

        { district: 'Munshiganj', value: 'Lohajang' },
        { district: 'Munshiganj', value: 'Sreenagar' },
        { district: 'Munshiganj', value: 'Munshiganj Sadar' },
        { district: 'Munshiganj', value: 'Sirajdikhan' },
        { district: 'Munshiganj', value: 'Tongibari' },
        { district: 'Munshiganj', value: 'Gazaria' },

        { district: 'Narayanganj', value: 'Narayanganj Sadar' },
        { district: 'Narayanganj', value: 'Sonargaon' },
        { district: 'Narayanganj', value: 'Bandar' },
        { district: 'Narayanganj', value: 'Araihazar' },
        { district: 'Narayanganj', value: 'Rupganj' },

        { district: 'Narsingdi', value: 'Belabo' },
        { district: 'Narsingdi', value: 'Monohardi' },
        { district: 'Narsingdi', value: 'Narsingdi Sadar' },
        { district: 'Narsingdi', value: 'Palash' },
        { district: 'Narsingdi', value: 'Raipura' },
        { district: 'Narsingdi', value: 'Shibpur' },

        { district: 'Rajbari', value: 'Baliakandi' },
        { district: 'Rajbari', value: 'Goalanda' },
        { district: 'Rajbari', value: 'Pangsha' },
        { district: 'Rajbari', value: 'Kalukhali' },
        { district: 'Rajbari', value: 'Rajbari Sadar' },

        { district: 'Tangail', value: 'Tangail Sadar' },
        { district: 'Tangail', value: 'Sakhipur' },
        { district: 'Tangail', value: 'Basail' },
        { district: 'Tangail', value: 'Madhupur' },
        { district: 'Tangail', value: 'Ghatail' },
        { district: 'Tangail', value: 'Kalihati' },
        { district: 'Tangail', value: 'Nagarpur' },
        { district: 'Tangail', value: 'Mirzapur' },
        { district: 'Tangail', value: 'Gopalpur' },
        { district: 'Tangail', value: 'Delduar' },
        { district: 'Tangail', value: 'Bhuapur' },
        { district: 'Tangail', value: 'Dhanbari' },

        { district: 'Bagerhat', value: 'Bagerhat Sadar' },
        { district: 'Bagerhat', value: 'Chitalmari' },
        { district: 'Bagerhat', value: 'Fakirhat' },
        { district: 'Bagerhat', value: 'Kachua' },
        { district: 'Bagerhat', value: 'Mollahat' },
        { district: 'Bagerhat', value: 'Mongla' },
        { district: 'Bagerhat', value: 'Morrelganj' },
        { district: 'Bagerhat', value: 'Rampal' },
        { district: 'Bagerhat', value: 'Sarankhola' },

        { district: 'Chuadanga', value: 'Alamdanga' },
        { district: 'Chuadanga', value: 'Chuadanga Sadar' },
        { district: 'Chuadanga', value: 'Jibannagar' },
        { district: 'Chuadanga', value: 'Damurhuda' },
        { district: 'Chuadanga', value: 'Darsana' },

        { district: 'Jessore', value: 'Abhaynagar' },
        { district: 'Jessore', value: 'Bagherpara' },
        { district: 'Jessore', value: 'Chaugachha' },
        { district: 'Jessore', value: 'Jessore Sadar' },
        { district: 'Jessore', value: 'Jhikargachha' },
        { district: 'Jessore', value: 'Keshabpur' },
        { district: 'Jessore', value: 'Manirampur' },
        { district: 'Jessore', value: 'Sharsha' },

        { district: 'Jhenaidah', value: 'Jhenaidah' },
        { district: 'Jhenaidah', value: 'Kaliganj' },
        { district: 'Jhenaidah', value: 'Kotchandpur' },
        { district: 'Jhenaidah', value: 'Shailkupa' },
        { district: 'Jhenaidah', value: 'Maheshpur' },
        { district: 'Jhenaidah', value: 'Harinakunda' },

        { district: 'Khulna', value: 'Batiaghata' },
        { district: 'Khulna', value: 'Dacope' },
        { district: 'Khulna', value: 'Dighalia' },
        { district: 'Khulna', value: 'Dumuria' },
        { district: 'Khulna', value: 'Paikgachha' },
        { district: 'Khulna', value: 'Phultala' },
        { district: 'Khulna', value: 'Rupsha' },
        { district: 'Khulna', value: 'Terokhada' },
        { district: 'Khulna', value: 'Koyra' },

        { district: 'Kushtia', value: 'Bheramara' },
        { district: 'Kushtia', value: 'Daulatpur' },
        { district: 'Kushtia', value: 'Khoksa' },
        { district: 'Kushtia', value: 'Kumarkhali' },
        { district: 'Kushtia', value: 'Kushtia Sadar' },
        { district: 'Kushtia', value: 'Mirpur' },

        { district: 'Magura', value: 'Magura Sadar' },
        { district: 'Magura', value: 'Mohammadpur' },
        { district: 'Magura', value: 'Shalikha' },
        { district: 'Magura', value: 'Sreepur' },

        { district: 'Meherpur', value: 'Gangni' },
        { district: 'Meherpur', value: 'Meherpur Sadar' },
        { district: 'Meherpur', value: 'Mujibnagar' },

        { district: 'Narail', value: 'Kalia' },
        { district: 'Narail', value: 'Lohagara' },
        { district: 'Narail', value: 'Narail Sadar' },

        { district: 'Satkhira', value: 'Satkhira Sadar' },
        { district: 'Satkhira', value: 'Assasuni' },
        { district: 'Satkhira', value: 'Debhata' },
        { district: 'Satkhira', value: 'Tala' },
        { district: 'Satkhira', value: 'Kalaroa' },
        { district: 'Satkhira', value: 'Kaliganj' },
        { district: 'Satkhira', value: 'Shyamnagar' },

        { district: 'Joypurhat', value: 'Akkelpur' },
        { district: 'Joypurhat', value: 'Joypurhat Sadar' },
        { district: 'Joypurhat', value: 'Kalai' },
        { district: 'Joypurhat', value: 'Khetlal' },
        { district: 'Joypurhat', value: 'Panchbibi' },

        { district: 'Bogura', value: 'Adamdighi' },
        { district: 'Bogura', value: 'Bogura Sadar' },
        { district: 'Bogura', value: 'Dhunat' },
        { district: 'Bogura', value: 'Dhupchanchia' },
        { district: 'Bogura', value: 'Gabtali' },
        { district: 'Bogura', value: 'Kahaloo' },
        { district: 'Bogura', value: 'Nandigram' },
        { district: 'Bogura', value: 'Sariakandi' },
        { district: 'Bogura', value: 'Shajahanpur' },
        { district: 'Bogura', value: 'Sherpur' },
        { district: 'Bogura', value: 'Shibganj' },
        { district: 'Bogura', value: 'Sonatola' },

        { district: 'Naogaon', value: 'Atrai' },
        { district: 'Naogaon', value: 'Badalgachhi' },
        { district: 'Naogaon', value: 'Manda' },
        { district: 'Naogaon', value: 'Dhamoirhat' },
        { district: 'Naogaon', value: 'Mohadevpur' },
        { district: 'Naogaon', value: 'Naogaon Sadar' },
        { district: 'Naogaon', value: 'Niamatpur' },
        { district: 'Naogaon', value: 'Patnitala' },
        { district: 'Naogaon', value: 'Porsha' },
        { district: 'Naogaon', value: 'Raninagar' },
        { district: 'Naogaon', value: 'Sapahar' },

        { district: 'Natore', value: 'Bagatipara' },
        { district: 'Natore', value: 'Baraigram' },
        { district: 'Natore', value: 'Gurudaspur' },
        { district: 'Natore', value: 'Lalpur' },
        { district: 'Natore', value: 'Natore Sadar' },
        { district: 'Natore', value: 'Singra' },
        { district: 'Natore', value: 'Naldanga' },

        { district: 'Nawabganj', value: 'Bholahat' },
        { district: 'Nawabganj', value: 'Gomastapur' },
        { district: 'Nawabganj', value: 'Nachole' },
        { district: 'Nawabganj', value: 'Nawabganj Sadar' },
        { district: 'Nawabganj', value: 'Shibganj' },

        { district: 'Pabna', value: 'Atgharia' },
        { district: 'Pabna', value: 'Bera' },
        { district: 'Pabna', value: 'Bhangura' },
        { district: 'Pabna', value: 'Chatmohar' },
        { district: 'Pabna', value: 'Faridpur' },
        { district: 'Pabna', value: 'Ishwardi' },
        { district: 'Pabna', value: 'Pabna Sadar' },
        { district: 'Pabna', value: 'Santhia' },
        { district: 'Pabna', value: 'Sujanagar' },

        { district: 'Sirajganj', value: 'Belkuchi' },
        { district: 'Sirajganj', value: 'Chauhali' },
        { district: 'Sirajganj', value: 'Kamarkhanda' },
        { district: 'Sirajganj', value: 'Kazipur' },
        { district: 'Sirajganj', value: 'Raiganj' },
        { district: 'Sirajganj', value: 'Shahjadpur' },
        { district: 'Sirajganj', value: 'Sirajganj Sadar' },
        { district: 'Sirajganj', value: 'Tarash' },
        { district: 'Sirajganj', value: 'Ullahpara' },

        { district: 'Rajshahi', value: 'Bagha' },
        { district: 'Rajshahi', value: 'Bagmara' },
        { district: 'Rajshahi', value: 'Charghat' },
        { district: 'Rajshahi', value: 'Durgapur' },
        { district: 'Rajshahi', value: 'Godagari' },
        { district: 'Rajshahi', value: 'Mohanpur' },
        { district: 'Rajshahi', value: 'Paba' },
        { district: 'Rajshahi', value: 'Puthia' },
        { district: 'Rajshahi', value: 'Tanore' },

        { district: 'Dinajpur', value: 'Birampur' },
        { district: 'Dinajpur', value: 'Birganj' },
        { district: 'Dinajpur', value: 'Biral' },
        { district: 'Dinajpur', value: 'Bochaganj' },
        { district: 'Dinajpur', value: 'Chirirbandar' },
        { district: 'Dinajpur', value: 'Phulbari' },
        { district: 'Dinajpur', value: 'Ghoraghat' },
        { district: 'Dinajpur', value: 'Hakimpur' },
        { district: 'Dinajpur', value: 'Kaharole' },
        { district: 'Dinajpur', value: 'Khansama' },
        { district: 'Dinajpur', value: 'Dinajpur Sadar' },
        { district: 'Dinajpur', value: 'Nawabganj' },
        { district: 'Dinajpur', value: 'Parbatipur' },

        { district: 'Gaibandha', value: 'Phulchhari' },
        { district: 'Gaibandha', value: 'Gaibandha Sadar' },
        { district: 'Gaibandha', value: 'Gobindaganj' },
        { district: 'Gaibandha', value: 'Palashbari' },
        { district: 'Gaibandha', value: 'Sadullapur' },
        { district: 'Gaibandha', value: 'Sughatta' },
        { district: 'Gaibandha', value: 'Sundarganj' },

        { district: 'Kurigram', value: 'Bhurungamari' },
        { district: 'Kurigram', value: 'Char Rajibpur' },
        { district: 'Kurigram', value: 'Chilmari' },
        { district: 'Kurigram', value: 'Phulbari' },
        { district: 'Kurigram', value: 'Kurigram Sadar' },
        { district: 'Kurigram', value: 'Nageshwari' },
        { district: 'Kurigram', value: 'Rajarhat' },
        { district: 'Kurigram', value: 'Raomari' },
        { district: 'Kurigram', value: 'Ulipur' },

        { district: 'Lalmonirhat', value: 'Aditmari' },
        { district: 'Lalmonirhat', value: 'Hatibandha' },
        { district: 'Lalmonirhat', value: 'Kaliganj' },
        { district: 'Lalmonirhat', value: 'Lalmonirhat Sadar' },
        { district: 'Lalmonirhat', value: 'Patgram' },

        { district: 'Nilphamari', value: 'Dimla' },
        { district: 'Nilphamari', value: 'Domar' },
        { district: 'Nilphamari', value: 'Jaldhaka' },
        { district: 'Nilphamari', value: 'Kishoreganj' },
        { district: 'Nilphamari', value: 'Nilphamari Sadar' },
        { district: 'Nilphamari', value: 'Saidpur' },

        { district: 'Panchagarh', value: 'Atwari' },
        { district: 'Panchagarh', value: 'Boda' },
        { district: 'Panchagarh', value: 'Debiganj' },
        { district: 'Panchagarh', value: 'Panchagarh Sadar' },
        { district: 'Panchagarh', value: 'Tetulia' },

        { district: 'Rangpur', value: 'Badarganj' },
        { district: 'Rangpur', value: 'Gangachhara' },
        { district: 'Rangpur', value: 'Kaunia' },
        { district: 'Rangpur', value: 'Rangpur Sadar' },
        { district: 'Rangpur', value: 'Mithapukur' },
        { district: 'Rangpur', value: 'Pirgachha' },
        { district: 'Rangpur', value: 'Pirganj' },
        { district: 'Rangpur', value: 'Taraganj' },

        { district: 'Thakurgaon', value: 'Baliadangi' },
        { district: 'Thakurgaon', value: 'Haripur' },
        { district: 'Thakurgaon', value: 'Pirganj' },
        { district: 'Thakurgaon', value: 'Ranisankail' },
        { district: 'Thakurgaon', value: 'Thakurgaon Sadar' },

        { district: 'Netrokona', value: 'Atpara' },
        { district: 'Netrokona', value: 'Barhatta' },
        { district: 'Netrokona', value: 'Durgapur' },
        { district: 'Netrokona', value: 'Khaliajuri' },
        { district: 'Netrokona', value: 'Kalmakanda' },
        { district: 'Netrokona', value: 'Kendua' },
        { district: 'Netrokona', value: 'Madan' },
        { district: 'Netrokona', value: 'Mohanganj' },
        { district: 'Netrokona', value: 'Netrokona Sadar' },
        { district: 'Netrokona', value: 'Purbadhala' },

        { district: 'Sherpur', value: 'Jhenaigati' },
        { district: 'Sherpur', value: 'Nakla' },
        { district: 'Sherpur', value: 'Nalitabari' },
        { district: 'Sherpur', value: 'Sherpur Sadar' },
        { district: 'Sherpur', value: 'Sreebardi' },

        { district: 'Jamalpur', value: 'Baksiganj' },
        { district: 'Jamalpur', value: 'Dewanganj' },
        { district: 'Jamalpur', value: 'Islampur' },
        { district: 'Jamalpur', value: 'Jamalpur Sadar' },
        { district: 'Jamalpur', value: 'Madarganj' },
        { district: 'Jamalpur', value: 'Melandaha' },
        { district: 'Jamalpur', value: 'Sarishabari' },

        { district: 'Mymensingh', value: 'Trishal' },
        { district: 'Mymensingh', value: 'Dhobaura' },
        { district: 'Mymensingh', value: 'Fulbaria' },
        { district: 'Mymensingh', value: 'Gafargaon' },
        { district: 'Mymensingh', value: 'Gauripur' },
        { district: 'Mymensingh', value: 'Haluaghat' },
        { district: 'Mymensingh', value: 'Ishwarganj' },
        { district: 'Mymensingh', value: 'Mymensingh Sadar' },
        { district: 'Mymensingh', value: 'Muktagachha' },
        { district: 'Mymensingh', value: 'Nandail' },
        { district: 'Mymensingh', value: 'Phulpur' },
        { district: 'Mymensingh', value: 'Bhaluka' },
        { district: 'Mymensingh', value: 'Tara Khanda' },

        { district: 'Habiganj', value: 'Ajmiriganj' },
        { district: 'Habiganj', value: 'Bahubal' },
        { district: 'Habiganj', value: 'Baniyachong' },
        { district: 'Habiganj', value: 'Chunarughat' },
        { district: 'Habiganj', value: 'Habiganj Sadar' },
        { district: 'Habiganj', value: 'Lakhai' },
        { district: 'Habiganj', value: 'Madhabpur' },
        { district: 'Habiganj', value: 'Nabiganj' },
        { district: 'Habiganj', value: 'Shayestaganj' },

        { district: 'Moulvibazar', value: 'Barlekha' },
        { district: 'Moulvibazar', value: 'Juri' },
        { district: 'Moulvibazar', value: 'Kamalganj' },
        { district: 'Moulvibazar', value: 'Kulaura' },
        { district: 'Moulvibazar', value: 'Moulvibazar Sadar' },
        { district: 'Moulvibazar', value: 'Rajnagar' },
        { district: 'Moulvibazar', value: 'Sreemangal' },

        { district: 'Sunamganj', value: 'Bishwamvarpur' },
        { district: 'Sunamganj', value: 'Chhatak' },
        { district: 'Sunamganj', value: 'Shantiganj' },
        { district: 'Sunamganj', value: 'Derai' },
        { district: 'Sunamganj', value: 'Dharamapasha' },
        { district: 'Sunamganj', value: 'Dowarabazar' },
        { district: 'Sunamganj', value: 'Jagannathpur' },
        { district: 'Sunamganj', value: 'Jamalganj' },
        { district: 'Sunamganj', value: 'Sullah' },
        { district: 'Sunamganj', value: 'Sunamganj Sadar' },
        { district: 'Sunamganj', value: 'Tahirpur' },
        { district: 'Sunamganj', value: 'Modhyanagar' },

        { district: 'Sylhet', value: 'Balaganj' },
        { district: 'Sylhet', value: 'Beanibazar' },
        { district: 'Sylhet', value: 'Bishwanath' },
        { district: 'Sylhet', value: 'Companigonj' },
        { district: 'Sylhet', value: 'Dakshin Surma' },
        { district: 'Sylhet', value: 'Fenchuganj' },
        { district: 'Sylhet', value: 'Golapganj' },
        { district: 'Sylhet', value: 'Gowainghat' },
        { district: 'Sylhet', value: 'Jaintiapur' },
        { district: 'Sylhet', value: 'Kanaighat' },
        { district: 'Sylhet', value: 'Osmani Nagar' },
        { district: 'Sylhet', value: 'Sylhet Sadar' },
        { district: 'Sylhet', value: 'Zakiganj' },
    ];

    getCountries() {
        return this.countries;
    }

    getDivisions(country) {
        return this.divisions.filter(function(division) {
            return division.country == country;
        });
    }

    getDistricts(division) {
        return this.districts.filter(function(district) {
            return district.division == division;
        });
    }

    getThanas(district) {
        return this.thanas.filter(function(thana) {
            return thana.district == district;
        });
    }

    getAllPlaces() {
        return [
            "Bagerhat", "Bandarban", "Barguna", "Barishal", "Bhola", "Bogra", "Brahmanbaria", "Chandpur",
            "Chapinawabganj", "Chittagong", "Chuadanga", "Comilla", "Cox`s Bazar", "Dhaka", "Dinajpur", "Faridpur",
            "Feni", "Gaibandha", "Gazipur", "Gopalganj", "Hobiganj", "Jamalpur", "Jessore", "Jhalokathi",
            "Jinaidaha", "Joypurhat", "Khagrachari", "Khulna", "Kishoreganj", "Kurigram", "Kustia", "Lakshmipur",
            "Lalmonirhat", "Madaripur", "Magura", "Manikganj", "Meherpur", "Moulvibazar", "Munshiganj", "Mymensingh",
            "Naogaon", "Narail", "Narayanganj", "Narshingdi", "Natore", "Netrakona", "Nilphamari", "Noakhali",
            "Pabna", "Panchagarh", "Patuakhali", "Pirojpur", "Rajbari", "Rajshahi", "Rangamati", "Rangpur",
            "Satkhira", "Shariatpur", "Sherpur", "Sirajganj", "Sunamganj", "Sylhet", "Tangail", "Thakurgaon",
            "Adabor", "Aditmari", "Agailzhara", "agrabad", "Ahsanganj", "Airport ", "ajompur", "Akhaura", "Akkelpur",
            "Alaipur", "Alamdanga", "Alamdighi", "Alfadanga", "Alikadam", "Amtali", "Anawara", "Araihazar", "Arpara",
            "Ashashuni", "asuliya", "Atpara", "Azmireeganj", "Babuganj", "Badalgachhi", "Badarganj", "Badda", "Bagerhat Sadar",
            "Bagha", "Bagharpara", "Bahubal", "Baidder Bazar", "Baiddya Jam Toil", "Bajitpur", "Bakshigonj", "Balaganj", "Baliadangi",
            "Baliakandi", "Bamna", "Banani", "Banaripara", "Banchharampur", "Bandar", "Bandarban Sadar", "Bangla Hili", "Banglamotor ",
            "Baniachang", "Banwarinagar", "Barajalia", "Barakal", "Baralekha", "Barguna Sadar", "Barhamganj", "Barhatta", "Baridhara ",
            "Barishal Sadar", "Barura", "basabo", "Basail", "Bashundhara ", "Basurhat", "Batiaghat", "Bauphal", "Begumganj",
            "Belabo", "Belkuchi", "Benapule", "Bera", "Betagi", "Bhabaniganj", "Bhairob", "Bhaluka", "Bhandaria",
            "Bhanga", "Bhangura", "Bhedorganj", "Bheramara", "Bhola Sadar", "Bholahat", "Bhuapur", "Bhurungamari", "Bianibazar",
            "Bilaichhari", "Biral", "Birampur", "Birganj", "Bishamsarpur", "Bishwanath", "Boalkhali", "Boalmari", "Boda",
            "Bogra Sadar", "Bonarpara", "Bondor Bazar", "Bongshal", "Borhanuddin UPO", "Borobazar", "Brahamanbaria Sadar", "Brahmanpara", "Burichang",
            "Chalna Ankorage", "Chalna Bazar", "Chandina", "Chandpur Sadar", "Chapinawabganj Sadar", "Char Alexgander", "Charbhadrasan", "Charfashion", "Charghat",
            "Chasara", "Chatkhil", "Chatmohar", "Chaugachha", "Chawak Bazar", "Chhagalnaia", "Chhatak", "Chilmari", "Chiringga",
            "Chitalmari", "Chittagong Sadar", "Chok bazzar", "Chomohone", "Chotto Dab", "Chouddagram", "Chrirbandar", "Chuadanga Sadar", "Chunarughat",
            "Chwakbazar", "Comilla", "Comilla Sadar", "Companiganj", "Dabiganj", "Dagonbhuia", "Dakkhin khan",
            "Dakshinkhan", "Damudhya", "Damurhuda", "Darussalam", "Dashmina", "Daudkandi", "Davidhar", "Debbhata", "Debottar",
            "Delduar", "Demra", "Dewangonj", "Dhaka Cantt.", "Dhamrai", "Dhamuirhat", "Dhangora", "Dhanmondi", "Dharmapasha",
            "Dhirai Chandpur", "Dhobaura", "Dhunat", "Digalia", "Diginala", "Dimla", "Dinajpur Sadar", "Domar", "Doulatganj",
            "Doulatkhan", "Doulatpur", "Duara bazar", "Dupchachia", "Durgapur", "East Joara", "Fakirhat", "Faridganj", "Faridpur Sadar",
            "Fatikchhari", "Fatullah", "Fenchuganj", "Feni Sadar", "Fulbaria", "Gabtoli", "Gaforgaon", "Gaibandha Sadar", "Gajaria",
            "Galachipa", "Gangachara", "Gangni", "Gazipur Sadar", "Gendariya", "Ghatail", "Gheor", "Ghungiar", "Ghuramara",
            "Goainhat", "Gobindaganj", "Godagari", "Gopalganj", "Gopalganj Sadar", "Gopalpur", "Gopalpur UPO", "Gorakghat", "Gosairhat",
            "Gouranadi", "Gouripur", "Gulshan", "Gulshan-1 ", "hajaribug", "Hajiganj", "Hajirhat", "HAlishahar", "Haluaghat",
            "Harinakundu", "Harua", "Hatgurudaspur", "Hathazari", "Hatibandha", "hatirpul", "Hatiya", "Hatshoshiganj", "Hayemchar",
            "Hazaribag", "Hobiganj Sadar", "Homna", "Hossenpur", "Ishwardi", "Islampur", "Isshwargonj", "Itna", "Jaflong",
            "Jagnnathpur", "Jaintapur", "Jajira", "Jakiganj", "Jaldhaka", "Jaldi", "Jamalpur", "Janipur", "Jarachhari",
            "Jatrabari", "Jessore Sadar", "Jhalokathi Sadar", "Jhikargachha", "Jhinaigati", "Jibanpur", "Jinaidaha Sadar", "Joypara", "Joypurhat Sadar",
            "Kachua", "Kachua UPO", "Kafrul", "Kahalu", "kalai", "Kalampati", "kalaroa", "Kalauk", "Kalia",
            "Kaliakaar", "Kaliganj", "Kaliganj UPO", "Kalihati", "kalkini", "Kallyanpur", "Kalmakanda", "Kamalganj", "Kanaighat",
            "Kapashia", "kapasia", "kaptai", "Karimganj", "karwan Bazar", "Kasba", "Kashiani", "Kashkaolia", "Kathalia",
            "Katiadi", "kaukhali", "Kaunia", "Kawran bazzar", "Kazipur", "Kendua", "Keraniganj", "Keshabpur", "Khagrachari Sadar",
            "Khaliajuri", "Khansama", "Khas Kowlia", "Khepupara", "Khetlal", "Khilgaon", "Khilkhet", "Khod Mohanpur", "Khulna Sadar",
            "Kishoreganj Sadar", "Kishoriganj", "Kodomtoli", "kolabagan", "Kompanyganj", "Kotalipara", "Kotchandpur", "Kotowali", "Kotwali",
            "Kulaghat", "Kulaura", "Kuliarchar", "Kumarkhali", "Kurigram Sadar", "Kustia Sadar", "Kutubdia", "Lakkhipur", "Laksam",
            "Lakshimpur Sadar", "Lalbag", "Lalitganj", "Lalmohan UPO", "Lalmonirhat Sadar", "Langalkot", "Laxman", "Laxmichhari", "Laxmipasha",
            "Lechhraganj", "Lohagara", "Lohajong", "Longachh", "Madan", "Madaripur Sadar", "Madhabpur", "Madhupur", "Madinabad",
            "Madukhali", "Magura Sadar", "Mahadebpur", "Mahalchhari", "Maharajganj", "Mahendiganj", "Maheshpur", "Maijdee", "Majhira",
            "Maksudpur", "Malandah", "Malibagh", "Manikchhari", "Manikganj Sadar", "Marishya", "Mathargonj", "Mathbaria", "Matiranga",
            "Matlobganj", "Meherpur Sadar", "Mirpur", "Mirpur Cantonment", "Mirsharai", "Mirzapur", "Mithamoin", "Mithapukur", "Moddhynagar",
            "mogbazar", "Mohajan", "Mohakhali", "Mohammadpur", "Mohanganj", "Mollahat", "Monirampur", "Monnunagar", "Monohordi",
            "Morelganj", "mothertek", "Motijheel", "moulobi bszar", "Moulvibazar Sadar", "Mugdapara", "Muktagachha", "Muladi", "Munshiganj Sadar",
            "Muradnagar", "Mymensingh Sadar", "Nababganj", "Nabiganj", "Nabinagar", "Nachol", "nadda", "Nagarkanda", "Nagarpur",
            "Nageshwar", "Naikhong", "Nakipur", "Nakla", "Nalchhiti", "Naldanga", "Nalitabari", "Nandail", "Nandigram",
            "Naniachhar", "Naogaon Sadar", "Narail Sadar", "Narayanganj Sadar", "Naria", "Narshingdi Sadar", "Nasirabaad", "Nasirnagar", "Natore Sadar",
            "Nawabganj", "Nazirpur", "Netrakona Sadar", "New market", "Niamatpur", "Nikli", "Nilkhet Pilkhana", "Nilphamari Sadar", "Nilphamari Sugar Mil",
            "Nitpur", "Noakhali", "Noakhali Sadar", "Noapara", "Osmanpur", "Ostagram", "Pabna Sadar", "Paikgachha", "Pakundia",
            "Palash", "Palashbari", "Pallabi", "Palton", "Panchagra Sadar", "panchbibi", "Panchhari", "Pangsha", "Panthapath",
            "Parbatipur", "Pashurampur", "Patgram", "Patharghata", "Patia Head Office", "Patnitala", "Patuakhali Sadar", "Phulbari", "Phulchhari",
            "Phulpur", "Phultala", "Pirgachha", "Pirganj", "Pirojpur Sadar", "pollobi", "polton", "Prasadpur", "Purbadhola",
            "Putia", "Rafayetpur", "Rajapur", "Rajarhat", "Rajbari Sadar", "Rajibpur", "Rajnagar", "Rajoir", "Rajshahi Sadar",
            "Rajsthali", "Ramganj", "Ramghar Head Office", "Ramna", "Rampal", "Rampura", "Ramu", "Rangamati Sadar", "Rangpur Sadar",
            "Rangunia", "Rani Sankail", "Raninagar", "Rayenda", "Rayerbazar", "Raypur", "Raypura", "Razarbagh", "Roanchhari",
            "Rohanpur", "Romna", "Roumari", "Rouzan", "Ruma", "Rupganj", "Rupnagar", "Saadullapur", "Sabujbag",
            "Sachna", "Sadarpur", "Sah ali ", "Sahebganj", "sahjahanpur", "Sajiara", "Sakhipur", "Sandwip", "Sapahar",
            "Sarail", "Sariakandi", "Sarsa", "Sathia", "Satkania", "Satkhira Sadar", "Saturia", "Savar", "sempur",
            "Senbag", "Setabganj", "Shah Ali", "Shahabagh", "Shahjadpur", "Shahjahanpur", "Shahrasti", "Shailakupa", "Shampur",
            "Shariatpur Sadar", "shemoli", "Sher-E-Bangla Nagar", "Sherpur", "Sherpur Shadar", "Shibganj", "Shibganj U.P.O", "Shibloya", "Shibpur",
            "Shodor Ghat", "Shorishabari", "Shriangan", "Shribardi", "Shripur", "Siddirganj", "Singari", "Singra", "Sirajdikhan",
            "Sirajganj Sadar", "Sitakunda", "soidpur", "Sonagazi", "Sonatola", "South Kallyanpur", "Sreepur", "Srimangal", "Srinagar",
            "Sripur", "Subidkhali", "Sujanagar", "Sunamganj Sadar", "Sundarganj", "Susung Durgapur", "Sutrapur", "Swarupkathi", "Syedpur",
            "Sylhet Sadar", "Taala", "Tahirpur", "Tangail Sadar", "Tangibari", "Tanor", "Taraganj", "Tarash", "Tarial",
            "Tejgaon", "Tejgaon Industrial Area", "Teknaf", "Terakhada", "Tetulia", "Thakurgaon Sadar", "Thanchi", "Trishal", "Tungipara",
            "turag", "Tushbhandar", "Ukhia", "Ulipur", "Ullapara", "Uttar khan", "Uttara", "Uzirpur", "Vatara",
            "Veramara", "Wari", "Zinda bazar",
        ];
    }
}